.btn{
    border-radius: 5px;
    outline: none;
    position: relative;
    overflow: hidden;
    &:before{
        content: '';
        position: absolute;
        right: 0;
        width: 0;
        background-color: rgb(255,255,255,0.2);
        height: 100%;
        top:0px;
        transition: width 0.3s ease-in-out;
    }
    &:hover,&:active{
        outline: none !important;
        background-color: transparent;
        &:before{
            width: 100%;

        }
    }
  
  
}
.btn-primary,
.btn-primary.disabled {
    background: linear-gradient(45deg,$light-red, $primary );
    border: 1px solid $primary;
    // box-shadow: $primary-shadow;
    transition: 0.2s ease-in;
    color:  $primary;
    &:hover {
        background: $primary;
        box-shadow: $primary-shadow-hover;
        color:$white !important;
    }
    &.active,
    &:active,
    &:focus {
        background: $primary !important;
        bottom: 1px solid $primary !important;
        box-shadow: $primary !important;
    }
    &.btn-effect{
        transition: 0.3s ease-in-out;
        &:hover,&:focus{
            background: transparent;
            color: $btn-hover1;
            border-color: $btn-hover1;
        }
    }
}
