@import'../../assets/scss/variable';
    
    .font-bold {
        font-weight: 700;
    }

    .font-normal {
        font-weight: normal;
    }

    .font-light {
        font-weight: 300;
    }
    .font-medium {
        font-weight: 500;
    }
    .font-italic {
        font-style: italic;
    }
    .font-16 {
        font-size: 16px;
    }
    .font-14 {
        font-size: 14px;
    }
    .font-10 {
        font-size: 10px;
    }
    .font-16 {
        font-size: 16px;
    }
    .font-18 {
        font-size: 18px;
    }
    .font-20 {
        font-size: 20px;
    }
    .font-24 {
        font-size: 24px;
    }
    .font-28 {
        font-size: 28px;
    }
    .font-30 {
        font-size: 30px;
    }
    .font-36 {
        font-size: 36px;
    }
    .font-xl {
        font-size: 5rem;
    }

.ff-playfair{
    font-family: 'Rubik';
}
.ff-sanspro{
    font-family: 'Roboto';
}