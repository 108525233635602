.custom-control{
    border: 0px;
    border-bottom: 1px solid $grey8;
    outline: none;
    padding: 8px 0px;
    width: 100%;
}
.custom-input-group{
    position: relative;
    display: flex;
    i{
        position: absolute;
        z-index: 999;
    }
}