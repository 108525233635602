// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$clothing-website-primary: mat-palette($mat-indigo);
$clothing-website-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$clothing-website-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$clothing-website-theme: mat-light-theme((
  color: (
    primary: $clothing-website-primary,
    accent: $clothing-website-accent,
    warn: $clothing-website-warn,
  )
));
.conta-iner {
  max-width: 1170px;
  width: 90%;
  margin: 0 auto;
}
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($clothing-website-theme);

/* You can add global styles to this file, and also import other style files */
/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
@import'./assets/scss/main.scss';
/* Importing Bootstrap SCSS file. */
.container{
  max-width: 1400px;
  margin: 0 auto;
  padding: 0px 50px;
}
figure {
  margin: 0px;
}
.py-equal{
  padding: 2rem 0;
}
.sun-wrapper {
    position: relative;
    z-index: 0;
  }
  .sun-emitter {
    position: absolute;
    width: 300px;
    height: 300px;
    border-radius: 9999px;
    background-color: rgba(255, 255, 255, 0.3);
    margin: 0px auto;
    inset: 0;
    z-index: -1;
    transform: translateY(-50%);
    top: 50%;
  }

  .sun-wave {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 9999px;
    background-color: rgb(255, 255, 255 , 0.5);
    opacity: 0;
    z-index: -1;
    pointer-events: none;
  }

  .sun-wave1 {
    animation: sonarWave 3s linear infinite;
  }

  .sun-wave2 {
    animation: sonarWave 3s 0.5s linear infinite;
  }

  .sun-wave3 {
    animation: sonarWave 3s 1s linear infinite;
  }

  .sonar-wave4 {
    animation: sonarWave 3s 1.5s linear infinite;
  }

  @keyframes sonarWave {
    from {
      opacity: 0.4;
    }
    to {
      transform: scale(2);
      opacity: 0;
    }
  }
  .btn-custom-close{
    position: absolute;
    right: 7px;
    top: 7px;
    z-index: 999;
    height: 29px;
    display: flex;
    width: 29px;
    align-items: center;
    justify-content: center;
    background-color: $danger;
  }


  .auth-element{
    .modal-content{
      border-radius: 10px;
      overflow: hidden;
      border:none;
    }
    .auth-content{
      padding: 1.5rem;
      padding-top: 35px;
    }
     .btn-custom-close{
      right: 10px;
      top: 10px;
  }
  .auth-box{
    height: 100%;
  }
  }
  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.social-icon {
  background: #FAFAFA;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
// custom checkbox
.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox .custom-checkbox-control {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.custon-checkbox-label {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border:1px solid $grey8;

}


/* On mouse-over, add a grey background color */
.custom-checkbox:hover input ~ .custon-checkbox-label {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked ~ .custom-checkbox-label {
  background-color: $primary;
}

/* Create the checkmark/indicator (hidden when not checked) */
.custom-checkbox-label:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked ~ .custon-checkbox-label:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .custon-checkbox-label:after {
  left: 6px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid $white;
  border-width: 0 2px 2px 0;
  transform: rotate(
45deg);
}
.custom-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;

}

/* Create a custom radio button */
.custom-radio-mark {
  position: absolute;
  top: 0px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: $white;
  border-radius: 50%;
  border:1px solid $primary;
}

/* On mouse-over, add a grey background color */
//  .custom-radio:hover input ~ .custom-radio-mark {
//   background-color: #ccc;
// }

/* When the radio button is checked, add a blue background */
.custom-radio input:checked ~ .custom-radio-mark {
  background-color: $white;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.custom-radio-mark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom-radio input:checked ~ .custom-radio-mark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.custom-radio .custom-radio-mark:after {
     top: 4px;
    left: 4px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $primary;
}
// scrollbar
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $grey8;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $grey8;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background:$grey8;
}
.range-slider{
  .ngx-slider
  {
    .ngx-slider-pointer{
    width: 25px;
    height: 25px;
    background-color: $primary;
    top: -11px;
  &:after{
    right: 0;
    left: 0;
    margin: 0px auto;
    top: 50%;
    transform: translateY(-50%);

  }
  &.ngx-slider-active{
    &:after{
      background-color: $white;
    }
  }

  }
  .ngx-slider-selection{
    background-color: $primary;
  }
}
}



.customAccord{
  padding: 0;
  border:none;
  .card-header{
    background-color: transparent;
    padding: 5px 10px;
    border: 1px solid $grey8;
  }
  .card-block{
    overflow: hidden;
    position: relative;
    // border-bottom: 1px solid $grey8;
  }
  .address-block{
    .custom-radio{
      position: absolute;
      left: 5px;
      top: 27px;
    }
  }
}

.banner-element{
    border-radius: 8px;
    // background: $bodycolor;
        max-width: 100%;
    margin: 0px auto;
    // width: calc(100% - 100px);
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 430px;
}
.second-bnr {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px;
}
.banner-content{
    padding: 3rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-right: 0px;

    h1{
        font-size: 5rem;
        @media(min-width:1400px){
            font-size: 6rem;
        }
    }

}
.adverstiment-banner{
    background: url(assets/images/advertisment.png);
    width: 100%;
    padding: 5rem 0px;
    background-size: cover;
    overflow: hidden;
    position: relative;
}
/* Importing Bootstrap SCSS file. */

.accordion-element,.form-element {
  .Seller-Details {
    align-items: center;
    }

.adre_main.adre_main_paymt {
    background: #FAFAFA;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    height: 130px;
    margin-left: 30px;
}
.pmt-icn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.del {
    position: absolute;
    right: 20px;
}
.custom-radio1 {
    position: relative;
    .custom-radio {
        position: absolute;
        left: 5px;
        top: 55px;
    }
}
.paymt-img figure {
    width: 50px;
    margin-right: 18px;
    img {
      width: 100%;
    }
}
}
.detail-element {
  border: 1px solid #D8D7D7;
  border-radius: 20px;
  padding: 1rem;
}
.reviwe_detail_left_inner {
  height: 130px;
  width: 130px;
  // border: 1px solid #D8D7D7;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: $grey9;
  figure {
      width: 90px;
      height: 90px;
      margin: 0;
      img {
          width: 100%;
          height: 100%;
          object-fit: cover;
      }
  }
}
.detail-element {
  background-color:$grey9;
}
.inpt {
  background: none;
}
.title_bar.cart {
  border-bottom: 2px solid #E1E1E1;
}
.product_detail_tab_content {
  border-bottom: 2px dashed #E1E1E1;
}

.sm-prod__card {
  align-items: center;
  border: 1px solid #DFDFDF;
}
.add-btn {
  width: 117px;
  border-radius: 30px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    width: 30px;
    height: 30px;
    background: #F9F9F9;
    border: none;
    border-radius: 5px;
    line-height: 20px;
    font-size: 17px;
    &:hover,:active {
      background: #2B9D13;
      color: #fff;
    }
    &:active {
      background: #2B9D13;
      color: #fff;
    }
}
 input {
  background-color: transparent;
  color: #1A1A1A;
  border: navajowhite;
  width: 32px;
  text-align: center;
  font-size: 19px;
}
}
.carts {
  border-radius: 8px;
  .item-del {
    top: 56px;
    cursor: pointer;
    right: 20px;
}
}
.icon-upload{
  position: absolute;
  right: 0;
  top: 0;
  &.rotate-icon{
    transform: rotate(-45deg);
  }
}
.cr-point{
  cursor: pointer;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
  border-color: $primary;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple{
  background-color: $primary;
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar{
  background-color: $primary;
}
.custom-upload{
  .custom-upload-btn{
  width: 175px;
    height: 175px;
    // background: transparent;
    border: 1px solid $grey9;
    position: relative;
    overflow: hidden;
    padding: 0;
    img {
      width: 100%;
    height: 100% !important;
    object-fit: cover;
    }
  }
  .custom-upload-control{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    left:0px;
    top:0px;
    cursor:pointer;
  }
}

.wdt {
  width: 94%;
  margin-left: 30px;
}
.chat {
  right: 15px;
  bottom: 36px;
}
.btn-tab-group{
  width: 100%;
  text-align: center;
  .btn:hover{
    background-color:linear-gradient(45deg,$light-red, $primary );
    color:$black;
  }
  .active{
      background-color: linear-gradient(45deg,$light-red, $primary );
      color:$white;
  }
}
.size{
  ul
  {
    display: flex;
    li{
     position: relative;
     overflow: hidden;
      width:40px;
      height: 40px;
      margin: 0px 10px;
      &:first-child{
        margin-left: 0px;
      }
      a{
        color: $light-text;
        background-color: $grey9;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background 0.3s ease-in-out;
        font-weight:600;
        &:hover,&.active{
          color: $white;
          background-color: $primary;
        }
      }
    }
  }
}
.btn:focus, .btn.focus {
  box-shadow: unset !important;
}
.hurt {
  position: absolute;
  top: -8px;
    right: -5px;
  cursor: pointer;
  width: 35px;
    height: 35px;
}
.img-box figure {
  position: absolute;
  top: 11px;
  bottom: 0;
  right: 0px;
}
.adverstiment-banner .img-box img {
  height: 330px;
}
.remd {
  .switch input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  .switch input[type=checkbox]:checked + label {
    background: #F2F2F2;
  }
  .switch input[type=checkbox]:checked + label::after {
    left: calc(100% - 4px);
    transform: translateX(-100%);
  }
  .switch label {
    cursor: pointer;
    width: 48px;
    height: 20px;
    background: #F2F2F2;
    display: block;
    border-radius: 24px;
    position: relative;
  }
  .switch label::after {
    content: "";
    position: absolute;
    top: 2px;
    left: 4px;
    width: 16px;
    height: 16px;
    background: #FFBB11;
    border-radius: 16px;
    transition: 0.3s;
  }
}
.chk {
  position: absolute;
  top: 8px;
  z-index: 1;
  right: 8px;
}
// a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus {
//   background: #2B9D13 !important;
//   box-shadow: 0 14px 26px -12px rgb(120 167 177 / 42%), 0 4px 23px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(82 147 159 / 20%) !important;
//   border: 1px solid #2B9D13 !important;
//   color: #ffffff !important;
// }
.saller-detail .lft figure {
  width: 115px;
  height: 115px;
  img {
    width: 100%;
  }
}
.sallr-ul {
  background-color: #fff;
    padding: 15px;
    margin: 0px 10px;
    width: 25%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid $grey8;
    border-radius: 8px;
    box-shadow: 1px 4px 6px rgb(0 0 0 / 5%);
}
.lft-radio {
  position: absolute;
  top: 50px;
  left: 0px;
}
.lft-add {
  position: absolute;
  top: 5px;
}
.contact {
  border: 1px solid #FFBB11;
}
.Explore {
  border: 1px solid $primary;
}
.logo-chkout {
  figure {
    width: 350px;
    img {
      width: 100%;
    };
  }
}
.btn-primary {
  border-color: transparent;
}
.btn-primary:hover {
  border-color: transparent;
}
a.text-primary:hover, a.text-primary:focus {
  color: $primary !important;
}
.modal-promo {
  position: relative;
.modal-header {
  padding: unset !important;
  border-bottom: unset !important;
  border-top-left-radius: unset !important;
  border-top-right-radius: unset !important;
}
.btn-close {
  width: unset !important;
  height: unset !important;
  position: absolute;
  right: 15px;
  font-size: 12px;
  top: 15px;
}
}
.promo {
  figure {
    width: 100px;
    height: 100px;
    img {
      width: 100%;
    }
  }
}
.carousel {
  .carousel-indicators {
    bottom: -50px;
    li {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $primary;
    }
  }
}
.carousel-control {
  display: none !important;
}
.add-btn {
  background-color: $grey9;
  margin-top: 10px;
}
.minus {
  background: #707070 !important;
  color: #fff;
}
.modal-content {
  border-radius: 1rem !important;
}
.viwe-btn{
  background: $viwe-btn;
}
.deal-banner {
  background: $deal-banner;
}
.py-equals{
.banner-element {
  background-size: cover;
  background-repeat: no-repeat;
}
}
.display-5 {
  font-size: 60px !important;
}
.shot-banner {
  background: linear-gradient(45deg,$light-red, $primary );
}
.sun-ban {
  figure {
    width: 460px;
    height: 420px;
    position: relative;
    top: 24px;
    left: 140px;
  }
}
.wdth {
  width: 65px;
  height: 65px;
}
.seller-product {
.py-equals {
  margin-top: 65px;
}
.banner-content {
  padding: 5rem;
}
}
.slick-next {
  margin-right: 50px !important;
}
.slick-prev {
  // display: none !important;
}
.slick-dots li {
  width: 5px !important;
}
.slick-dots {
  bottom: unset !important;
  top: -26px !important;
}
.slick-next:before {
  color: #E61C25 ;}
  .mat-checkbox-checked.mat-primary .mat-checkbox-background {
    background-color: #E61C25 !important;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: $light-primary !important;
}
.m-btm {
  margin-bottom: 2px !important;
}
.img-width {
  width: 20px;
}
.back-drop {
  background: #ffbb111c;
  margin: 15px 0px;
}
.shipd {
  border-radius: 5px 0px 0px 5px;
}
.map .panel-body.card-block.card-body {
  padding: 0px !important;
}
.home-bannr {
  .slick-dots {
    bottom: 5px !important;
}
.slick-dots li button:before {
  font-size: 10px !important;
}
.slick-dots li.slick-active button:before {
  color: #E61C25 !important;
}
}
.slick-prev:before {
  color: #E61C25;
}

 .slick-next:before{
  font-family:"Material Design Icons";
  content: "\F142";
  font-size: 25px;
    opacity: 1;
}
.slick-prev:before{
  font-family:"Material Design Icons";
  content: "\F142";
  font-size: 25px;
    opacity: 1 !important;
    transform: rotate(
      180deg);
}
button.slick-next.slick-arrow {
  background: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

button.slick-prev.slick-arrow {
  background: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  left: 27px;
}

.pac-container {
  z-index: 999999 !important;
}
.hm-banner1 {
  .slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    top: unset !important;
    height: unset !important;
    z-index: 1;
}
}
.equal-hight {
  min-height: 270px;
}
.img-hight {
  min-height: 360px;
}
.radio-class {
  margin-left:16px;
}
.auth-box {
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.cdk-overlay-pane {
  position: absolute !important;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  top: 176px;
  max-height: 100%;
}
.sub-flx{
  justify-content: space-between;
  align-items: stretch;
  .Subscription-box {
    width: 28%;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.12);
    h1 {
      font-size: 48px;
    }
}
}
.Subscriptions-pay {
  height: unset;
h4 {
  font-size: 25px;
}
h2 {
  font-size: 45px;
  margin-top: 5px;
}
}
.add-cpn {
  position: relative;
  padding: 30px;
  .cros {
    position: absolute;
    top: 0;
    right: 10px;
}
.img-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cntnt {
  h4 {
    font-size: 16px;
    margin: 0;
  }
  p {
    font-size: 14px;
    margin: 0;
  }
}
}


